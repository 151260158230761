import React, { useState, useEffect } from 'react'

import { FaSpinner } from 'react-icons/fa'

import styled, { keyframes } from 'styled-components'

import { useActivationCode } from '../hooks'

import { ACTIVATE_BUSINESS_CARD } from './data-provider'

import { useMutation } from '@apollo/react-hooks'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }

`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(FaSpinner)`
    font-size: 4rem;

    animation: ${spin} 1s linear infinite;
`

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 70vh;
`

const Title = styled.h1`
    margin: 0;
    margin-bottom: 2rem;
    padding: 1rem;
    text-align: center;
    animation: ${props => (props.active || props.error ? fadeIn : '')} 1s ease
        both;
    color: ${props =>
        props.active ? props.theme.green : props.error ? props.theme.red : ''};
`

export default () => {
    const [active, setActive] = useState(false)
    const [error, setError] = useState(false)
    const [activationCode] = useActivationCode()

    const [activateBusinessCard, { data }] = useMutation(ACTIVATE_BUSINESS_CARD)

    useEffect(() => {
        if (!data || !data.activateBusinessCard) return

        if (data.activateBusinessCard.type === 'SUCCESS') {
            setActive(true)

            setTimeout(function() {
                if (typeof window !== 'undefined') {
                    window.location = '/bc/' + data.activateBusinessCard.record.slug
                }
            }, 1000);
        } else if (data.activateBusinessCard.type === 'ERROR') {
            setError(data.activateBusinessCard.message)
        }
    }, [data])

    useEffect(() => {
        if (activationCode.length) {
            activateBusinessCard({
                variables: { activationCode },
            })
        }
        // eslint-disable-next-line
    }, [activationCode])

    return (
        <Container>
            {!active && !error && (
                <>
                    <Title>Activating your business card</Title>
                    <Spinner />
                </>
            )}
            {(active || error) && (
                <Title active={active ? 1 : 0} error={error ? 1 : 0}>
                    {active && 'Your business card is live'}
                    {error}
                </Title>
            )}
        </Container>
    )
}
