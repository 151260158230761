import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import ActivateBusinessCard from '../components/activate-business-card'

import Footer from '../components/footer'

import Header from '../components/header'

export default () => {
    return (
        <Layout>
            <Header mode="site-header" />
            <SEO title={'Activate your business card'} />
            <ActivateBusinessCard />
            <Footer />
        </Layout>
    )
}
